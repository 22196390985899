


































































@import "./src/assets/css/project-variables";

.pagination {
  position: relative;
  margin-top: .3rem;
  width: 100%;
  @media(max-width: 450px) {
    display: block;
    text-align: center;
    transform: translate(-18px, -7px) scale(0.8);
  }
  .counts-call {
    position: relative;
    right: 0;
    display: flex;
    transform: translateY(3px);
    @media(max-width: 450px) {
      display: block;
      text-align: center;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
      @media(max-width: 450px) {
        justify-content: center;
      }
      p {
        margin: 0;
      }

      .selectCountCalls {
        width:75px;

        ::v-deep .el-input{
          &__suffix-inner{

          }
          &__icon {
            line-height: 1;
          }

        }
      }
    }

    p {

    }
  }
  ::v-deep.el-input {
    &__inner {
      height: 30px;
      line-height: 30px;
    }

  }
}

