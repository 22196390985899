


















































































































































.group {
  position: relative;
}
.newInputNumber{
  max-width: 315px;
  margin-bottom: 15px;
  color: #1A2122;
  font-weight: 500;
  height: 40px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  border:solid 1px #DCDFE6;
  &:focus{
    border-color: #ef3123;
    outline: none;
  }
  &:disabled{
    background: #ddd;
    color: #000;
    font-weight: 600;
  }
}
.newInputNumber::-webkit-outer-spin-button,
.newInputNumber::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.input {
  max-width: 315px;
  margin-bottom: 15px;
  @media(max-width: 850px) {
    max-width: unset;
  }

  p {
    color: #1A2122;
    font-weight: 500;
    margin-bottom: 5px;

    span {
      font-size: 10px;
      font-weight: normal;
    }
    &.required {
      position: relative;
      margin-left: 10px;
      &:before {
        position: absolute;
        content: '*';
        color: #ef3123;
        font-size: 24px;
        left: -10px;
        top: -2px;
      }
    }
  }


  ::v-deep {
    .el-input-number {
      width: 100%;
    }

    .is-disabled {
      pointer-events: none;
      background: inherit;


      input {
        background: #fff;

        &:disabled {
          font-weight: bold !important;
          color: #1A2122;
        }
      }
    }

    .el-input {
      input {
        background: #fff;

        font-size: 12px;

      }
    }
  }
  &.warning {
    ::v-deep {
      input{
        background: #fff2c5 !important;
      }
    }
  }
}
