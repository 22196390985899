


































































































































































































































































































































































































































.for-print {
  display: none;
}

.documents {
  i {
    cursor: pointer;
  }

  &-header, &-content {
    display: grid;
  }

  &-header {
    grid-template-columns: 2fr 1fr;
    position: relative;

    > div {
      &:last-child {
        position: relative;

        i {
          position: absolute;
          top: 3px;
          left: -50px;
          color: #ef3123;
          font-size: 36px;
          cursor: pointer;
        }
      }
    }

  }

  &-content {
    height: 70vh;
    overflow: hidden;
    margin-top: 10px;
    grid-template-columns: 1fr 4fr;
    padding-left: 10px;

    h5 {
      margin-bottom: 10px;
    }

    &__fields {
      > div {
        height: 59vh;
        overflow-y: auto;
      }

      .field {
        border: 1px solid #ddd;
        padding: 5px;
        border-bottom: none;

        > div {
          &:last-child {
            font-weight: bold;
          }
        }

        &:last-child {
          border-bottom: 1px solid #ddd;
        }
      }
    }

    &__document {
      padding-left: 10px;
    }

    ::v-deep #docs {
      height: 55vh;
      overflow-y: auto;


      .mce-container-body.mce-flow-layout {
        display: none;
      }
      code {
        background: yellow;
      }
    }
  }

  ::v-deep .el-dialog {
    .header {
      font-size: 14px;
      margin-bottom: 10px;
      box-shadow: none;
      font-weight: bold;
    }

    .body {
      height: 59vh;
      overflow-y: auto;
      padding-bottom: 40px;
      .body-header, .body-content > div {
        display: grid;
        grid-template-columns: 2fr 3fr 2fr;
        column-gap: 10px;

      }

      .body-header {
        padding-bottom: 15px;

        align-items: center;
        .el-input {
          width: 90%;
        }
      }

      .body-content > div {
        margin-bottom: 5px;

        > div {
          padding: 5px 0;
          border-bottom: 1px solid #eaeaea;
          &:nth-child(2) {
            font-weight: bold;
          }
        }
      }
    }

    .form {
      margin-bottom: 20px;

      &-header, &-body {
        display: grid;
        grid-template-columns: 150px 1fr 150px 250px;
        column-gap: 40px;
        align-items: center;
      }
    }
  }

  .buttons {
    margin-top: 20px;
    text-align: right;
    > * {
      &:last-child {
        background: #ef3123;
        color: #fff;
      };
    }
  }
}
