
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































::v-deep .document-dialog {
  .el-dialog {
    margin-top: 1vh !Important;
  }

  .el-dialog__body {
    height: 90vh;
    overflow-y: auto;
    text-align: center;
  }
}

.document {
  h3 {
    font-weight: 500;
  }

  .button-group {
    position: relative;
    display: grid;
    grid-template-columns: 50% 1fr;
    column-gap: 60px;
    margin-bottom: 10px;

    .disabled {
      ::v-deep.el-upload {
        button {
          background: gray;
          border-color: gray;
          color: #fff
        }
      }
    }

    ::v-deep.el-upload {
      &-list {
        &__item-name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

      }
    }

    h6 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    p {
      font-size: 12px;
      color: #1A2122;
      margin-bottom: 10px;
    }

    .manager-info {
      margin-top: 1rem;

      > div {
        margin-bottom: .5rem;

        h6 {
          margin-bottom: 0;
        }
      }
    }
  }

  .content {
    margin-top: 30px;
    margin-left: 50px;
    @media (max-width: 500px) {
      margin: 20px 0;
    }

    .item {
      margin-bottom: 25px;

      h6 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      p {
        font-size: 12px;
        color: #1A2122;
        margin-bottom: 10px;
      }
    }
  }

  .for-admin-checker {
    margin-bottom: 2rem;
  }

  ::v-deep.el-loading-spinner {
    margin-top: -8px;

    .el-icon-loading {
      position: absolute;
      color: #fff;
      font-weight: bold;
    }
  }
  .comment-group {
    position: relative;
    width: 47%;
    left: 53%;
    //transform: translateY(-223px);
    &.client {
      //transform: translateY(-110px);
    }
    h6 {
      margin-bottom: .5rem;
    }
  }


}
