








































































































































































































































































































@import "../../assets/css/project-variables";
h4 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.variables {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 300px;
  * {
    width: 100%;
    margin-bottom: 5px;
  }
}
.newInputNumber{
  max-width: 315px;
  margin-bottom: 15px;
  color: #1A2122;
  font-weight: 500;
  height: 40px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  border:solid 1px #DCDFE6;
  &:focus{
    border-color: #ef3123;
    outline: none;
  }
  &:disabled{
    background: #ddd;
  }
}
.newInputNumber::-webkit-outer-spin-button,
.newInputNumber::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
::v-deep .switch .el-radio-group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
}
::v-deep .edit-for-role {
  display: none;
}
