















































.general-info {
  position: relative;
  top: -2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 40%;
  gap: 5px;
  &.empty {
    top: 1rem;
  }
  > div  {
    > div {
      text-align: center;
      &:last-child {
        color: #ef3123;
        font-weight: bold;
        > span {
          color: #1A2122;
        }
      }
    }
  }
}
