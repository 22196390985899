























































































.group {
  position: relative;
  display: inline-block;
}

.input {
  width: 100%;
  margin-bottom: 15px;

  @media(max-width: 850px) {
    max-width: unset;
  }

  p {
    color: #1A2122;
    font-weight: 500;
    margin-bottom: 5px;

    span {
      font-size: 10px;
      font-weight: normal;
    }

    &.required {
      position: relative;
      margin-left: 10px;

      &:before {
        position: absolute;
        content: '*';
        color: #ef3123;
        font-size: 24px;
        left: -10px;
        top: -2px;
      }
    }
  }

  ::v-deep {
    .disabled {
      pointer-events: none;
      background: inherit;

      input {
        background: #fff;

        &:disabled {
          font-weight: bold !important;
          color: #1A2122;
        }
      }
    }

    .el-input.is-disabled .el-input__inner {
      color: #000;
      font-weight: bold;
    }

    .el-input {
      input {
        background: #fff;
        font-size: 12px;
      }
    }

  }

  &.warning {
    ::v-deep {
      .el-input {
        input {
          background: #fff2c5 !important;
        }
      }
    }
  }
}

