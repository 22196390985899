







































































.menu {

}

.menu__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 3px 0px #00000040;
  padding: 10px;

  img {
    max-width: 90px;
    width: 100%;
  }

  button {
    border: none;
    background: inherit;
    cursor: pointer;

    i {
      font-size: 30px;
    }
  }
}

.menu__list {
  padding: 10px;

  nav {
    display: grid;

    align-items: center;
    width: 100%;

    div {
      border-bottom: solid 1px #bababa;
      width: 100%;
      display: flex;

      &.active {
        a {
          color: #ef3123 !important;
        }
      }

      a {
        padding: 10px;
        font-size: 16px;
        color: #1A2122 !important;
        width: 100%;


      }
    }

  }
}
