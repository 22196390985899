

















































































































.field-group.row {
  .disabled{

    .input.group, .group.select{
      pointer-events: none;
      ::v-deep{
        .el-input,.el-select{

          .el-input__inner{
            background:gray;
          }
        }
      }

    }
  }
  position: relative;
  display: grid;
  column-gap: 35px;
  grid-template-columns: 1fr 1fr 1fr;
  &.simple {
    grid-template-columns: 1fr;
  }

  i {
    font-style: normal;
  }

  &.one-row {
    grid-template-columns: 1fr;
  }

  @media(max-width: 1024px) {
    column-gap: 10px;
  }
  @media(max-width: 850px) {
    grid-template-columns: 1fr;
    column-gap: 0;
  }

  ::v-deep .el-input{
    input{
      background: #fff;
      font-size: 12px;
      &:disabled {
        background: #ddd;
        color: #000;
        font-weight: 600;

      }
    }
  }

}

.hide {
  display: none;
}
.info-byn{
  display: flex;
  align-items: center;
  p{
    margin-top: 20px;
    color: #1A2122;
    font-weight: 500;
    margin-bottom: 5px;
    @media (max-width: 850px) {
      margin-top: 0px;
    }
  }
}
.leasing_subject_all_price{
  max-width: 150px;
  grid-column-start: 3;
  margin-left: 70px;
  p{
    i{
      font-size: 13px;
    }
  }
}

