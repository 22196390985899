









































































































.page-menu {
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 2px 4px 0 #00000040;
  display: grid;
  overflow: hidden;

  button {
    cursor: pointer;
    padding: 20px 15px;
    border: none;
    background: #fff;
    border-bottom: solid 1px #bababa;
    transition: .3s;
    position: relative;
    @media(max-width: 1024px) {
      padding: 10px;
      font-size: 14px;
      font-weight: 500;
    }
    span{
      background: #fff;
      width: 5%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;

    }

    &.active {
      position: relative;
      span{
        background: #ef3123;
        width: 5%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
      //background: #ef3123;
      //color: #fff;
    }
  }
}
