






























































































































































































































.analisys {
  h3 {
    font-weight: 500;
    margin-bottom: 20px;

  }

  .inputs-block {
    &__item {
      position: relative;
      display: grid;
      gap: 40px;
      grid-template-columns: 1fr 1fr 1fr;
      @media(max-width: 1024px) {
        gap: 10px;
      }
      @media(max-width: 850px) {
        grid-template-columns: 1fr;
        gap: 0px;
      }
    }
  }
  .edit-for-role {
    position: absolute;
    right: 0;
  }

  .btn-block {
    display: grid;

    button {
      @media(max-width: 850px) {
        width: 100% !important;
        margin-bottom: 20px;
      }
    }

    &__wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 20px;
      @media(max-width: 850px) {
        display: block;
        width: 100%;
      }

      div {
        display: grid;
      }

      button {
        min-width: 240px;
        @media(max-width: 850px) {
          width: 100% !important;
          margin-bottom: 20px;
        }

        &.btn-accept {
          @media(max-width: 850px) {
            width: 100%;
            order: -1;
            margin-bottom: 5px;
          }

          i {
            font-size: 20px;
            line-height: 1;
          }
        }
      }

      p {
        padding: 5px 5px;
        text-align: start;
        max-width: 240px;
        font-size: 9px !important;
        color: #555;
        @media(max-width: 850px) {
          max-width: unset;
          width: 100%;

        }
      }
    }

    .next {
      margin-left: auto;
    }

  }
}

