


















































































































































::v-deep .smsDocuments-table {
  font-size: 9px;
  .el-table__row {
    td.el-table__cell{
      font-size: 10px;
      padding: 2px 0;
    }

  }
}
