
















































































































































































































































































































































































































































































.command-buttons {
  text-align: right;

  .add, .remove {
    cursor: pointer;
    display: inline-block;
    background: transparent;
    border: none;
    padding: 0;
    font-size: 24px;

    &:hover {
      opacity: .8;
      scale: 1.1;
    }
  }
}

.search {
  margin: 0.5rem 0;
  .el-input-group__append {
    background-color: transparent;
    color: transparent;
  }
  ::v-deep .el-input {
    margin-top: 5px;
    &-group {
      &__append {
        background-color: transparent !important;
        border: none;
        padding: 0 5px;
      }
    }
  }
}


::v-deep .el-table {
  &__header {
    thead {
      th {
        background: #eeeeee;
        color: #555;
        font-weight: 600;
      }
    }

    &-wrapper {

    }
  }

  tr {

  }

  &__cell {
    width: 10px !important;
    padding: 0.2rem 0;
    font-size: 10px;


    .status {
      border-radius: 5px;
      text-align: center;
      font-size: 10px;
    }

  }
}

::v-deep.el-pagination {
  margin-top: .5rem;
  text-align: center;
}

::v-deep .el-drawer {
  .content {
    display: grid;
    grid-template-columns: 70% 30%;
    gap: 30px;
    padding: 0 2rem 0 3rem;

    .el-form {

      .el-select {
        width: 100%;
      }

      .controls {
        text-align: right;

        > button {
          &:first-child {
            margin-right: 1rem;
          }
        }
      }
    }

    > div {
      &:last-child {
        > span {
          display: flex;
          align-items: center;
          gap: 10px;

          i {
            font-size: 24px;
            margin-top: -2px;
            cursor: pointer;
            //color: #ef3123;
          }
        }

      }
    }
  }

  .inner-content {
    padding: 0 1rem;

    > div {
      &:first-child {
        margin-bottom: 1rem;
        font-weight: bold;

        .el-input {
          margin-left: 1rem;

          &__inner {
            height: 32px;
          }
        }
      }
    }

    .leasing-offers-variants {
      .el-table {
        &__header {
          thead {
            th {
              text-align: center;
              font-size: 12px;
            }
          }
        }

        &__body {
          &-wrapper {

          }
        }

        &__row {
          td {
            text-align: center;
            padding: 1rem 0;
            font-size: 16px;

            .el-switch, .variant-name {
              margin: .5rem 0;
            }

            .offer-name {
              text-transform: uppercase;
              font-weight: bold;
              text-align: left;
              font-size: 12px;
            }
          }

          .el-input {
            width: 96%;
            &__inner {
              width: 95%;
              margin-left: -2px;
              height: 30px;
              line-height: 30px;
            }

          }
        }
      }
    }
  }
}
