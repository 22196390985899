







































































::v-deep .el-checkbox-group {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  @media (max-width: 1370px) {
    grid-template-columns: 1fr;
  }
}
