





























































































































































































































.edit-for-role {
  position: absolute;
  right: -5px;
  cursor: pointer;

  ::v-deep .el-dialog__body {
    max-height: 80vh;
    overflow-y: auto;
  }

  ::v-deep .el-form {
    .el-form-item {
      margin-bottom: 5px;
      display: flex;
      width: 100%;

      > label {
        text-align: left;
        width: 30%;
        font-weight: bold;
        color: #494949;
      }

      > div {
        width: 100%;
      }
    }

    .checkbox-items {
      margin-left: 40px;

      &__label {
        margin: 5px 0;
        color: #494949;
        font-size: 12px;
        font-weight: bold;
      }

      .el-input {
        margin-bottom: 5px;
      }

      //&__item {
      //  > div > div {
      //    display: grid;
      //    grid-template-columns: 3fr 1fr;
      //    gap: 20px;
      //    align-items: center;
      //     .el-form {
      //       &-item {
      //         width: 100%!important;
      //         margin: 0!important;
      //         > div {
      //           margin: 0!important;
      //           width: 100%!important;
      //         }
      //       }
      //    }
      //    .buttons {
      //      margin-top: -5px;
      //      .el-button {
      //        width: 100%;
      //        font-size: 12px;
      //        line-height: 1;
      //        height: 30px;
      //        margin-top: 5px;
      //        +.el-button  {
      //          margin-left: 0;
      //        }
      //      }
      //    }
      //  }
      //
      //
      //
      //}

      .add {
        span {
          display: inline-block;
          padding: 5px 10px;
          border: 1px solid #ef3123;
          color: #ef3123;
          margin: 5px 0;
          border-radius: 4px;
          cursor: pointer;
          background-color: transparent;
          transition: background-color .2s linear;

          i {
            padding-right: 5px;
          }

          &:hover {
            background-color: #fac1bd;
          }
        }

      }
    }

    .edit-table {
      margin-bottom: 10px;
      padding: 5px;
      > div {
        display: grid;
        grid-template-columns: 3fr 1fr 1fr 2fr;
        column-gap: 20px;
        &:first-child {
          font-size: 12px;
          font-weight: bold;
        }
      }
    }

    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .el-button {
        line-height: 1;
        font-size: 12.5px;
      }
    }
  }

}
