
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.context-menu {
  display: none;
  position: absolute;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  z-index: 100;
  &--view {
    background: #fff;
    display: none;
    width: 240px;
    position:absolute;
    border: 1px solid #ddd;
    font-size: 13px;
    padding: 10px 0;
    z-index: 101;
     span  {
       padding: 3px 0 3px 30px;
       display: inline-block;
       width: 100%;
       &:hover {
         background: #eee;
       }
       cursor: pointer;
     }
  }
}

.container {
  max-width: 1500px;
  width: 100%;
  padding: 15px 20px;
  margin: 0 auto;
  @media(max-width: 1024px) {
    padding: 10px 15px;
  }

  .export-settings {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 20px;
    margin-bottom: 30px;
     > div {
       display: flex;
       justify-content: space-between;
       column-gap: 10px;
       > span {
         max-width: 67%;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
       }
       > .el-switch {
         width: 30%;
       }
     }
  }

  .header {
    display: grid;
    grid-template-columns: 1fr 120px;
    box-shadow: none;

    .search {
      display: grid;
      grid-template-columns: 50px 1fr;
      margin-bottom: 1rem;
      .el-input-group__append {
        background-color: transparent;
        color: transparent;
        //padding: 0 50px;
      }
      ::v-deep .el-input {
        margin-top: 5px;
        &-group {
          &__append {
            background-color: transparent!important;
            border: none;
            padding: 0 5px;
          }
        }
      }
      > span {
        > img {
          cursor: pointer;
        }
      }


      ::v-deep .el-button {
        background: #ef3123;
        padding: 10px 50px;

        i.el-icon-search {
          color: #fff;
        }

        &:hover {
          opacity: .7;
        }
      }

      @media(max-width: 450px) {
        display: block;
        text-align: center;
        margin-bottom: 0.5rem;
      }
    }
  }


  .filter {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    transform: translateY(-5px);
    .el-icon-s-operation {
      cursor: pointer;
      font-size: 32px;
      margin-bottom: .8rem;
      transition: color .15s linear;

      &:hover,
      &.active {
        color: #ef3123;
      }
    }
    span {
      img {
        cursor: pointer;
        margin-bottom: 10px;
      }
    }

  }


  ::v-deep .el-table {
    &.admin {
      width: 100%;
    }
    &.client {
      margin-left : 51px;
      width: calc(100% - 51px)
    }
    &__header {
      thead {
        th {
          background: #eeeeee;
          color: #555;
          font-weight: 600;
        }
      }
      &-wrapper {

      }
    }
    .warning-row {
      background-color: #ffe4e1;
    }
    &__cell {
      padding: 5px 0;
      font-size: 10px;

      .status {
        border-radius: 5px;
        text-align: center;
        font-size: 10px;
      }
    }
  }

  ::v-deep.el-pagination {
    margin-top: .5rem;
    text-align: center;
    padding-left: 20rem;
  }

  ::v-deep.el-drawer {
    &__body {

      .el-form {
        max-width: 70%;
        margin: 0 auto;

        &-item {
          &__label {
            width: 100%;
            text-align: left;
          }

          .el-select,
          .el-date-editor {
            width: 100%;

            .el-tag {
              background: #ef3123;
              color: #fff;

              &__close,
              {
                color: #ef3123;
                background: #fff;
              }
            }
          }

          .el-input-number {
            width: 45%;

            &__prefix {
              padding: 0 .32rem;
            }

            &__increase,
            &__decrease {
              i {
                position: relative;
                top: 1px;
              }
            }

            &__increase {
              right: 2px;
              height: calc(100% - 3px);
            }

            &__decrease {
              height: calc(100% - 3px);
            }
          }
        }
      }
    }

    .buttons {
      text-align: right;

      button {
        &:first-child,
        &:nth-child(2) {
          margin-right: 1rem;
        }
      }
    }


  }

  .buttons {
    text-align: right;

    button {
      &:first-child,
      &:nth-child(2) {
        margin-right: 1rem;
      }
    }
  }
}

.content {
  width: 100%;
  height: calc(100vh - 103px);
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 2px 4px 0 #00000040;
}
::v-deep .general-info {
  top: -2.1rem;
  .el-loading-spinner {
    margin-top: -15px;
    .circular {
      height: 20px;
      width: 20px;
    }
  }
}


