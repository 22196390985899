
























































































































.container {
  max-width: 1500px;
  width: 100%;
  padding: 15px 20px;
  margin: 0 auto;

  @media(max-width: 1024px) {
    padding: 10px 15px;
  }
}

.wrapper {
  flex-basis: 100%;
  display: grid;
  grid-template-columns: 20% 1fr;
  align-items: flex-start;
  gap: 15px;
  @media(max-width: 1024px) {
    grid-template-columns: 1fr;
  }


  .content {
    padding: 15px 25px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 1px 2px 4px 0px #00000040;
    @media(max-width: 550px) {
      padding: 15px;
    }
  }
}
