











































































.phone_number_matches {
  max-width: 840px;
  margin-bottom: 20px;
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 10px;

  ul {
    overflow: auto;
    max-height: 300px;
    li {
      padding: 2px 5px;
      border-radius: 5px;
      display: grid;
      gap: 5px;
      grid-template-columns: 50px 100px 100px 140px 1fr;

      &:nth-child(odd) {
        background: #ededed;
      }

      div {
        &:nth-child(2) {
          color: #ef3123;
          cursor: pointer;
          &:hover{
            text-decoration: underline;
          }
        }
      }

      &:nth-child(1) {
        background: none;
        border-bottom: solid 1px #ddd;
        border-radius: 0;
        div {
          &:nth-child(2) {
            color: inherit;
          }
        }
      }
    }
  }
}

