




































































.container{
  max-width: 1360px;
  width: 100%;
  padding: 15px 20px;
  margin: 0 auto;
  @media(max-width: 1024px){
    padding: 10px 15px;
  }
}
.content {
  width: 100%;
  height: 88vh;
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 2px 4px 0px #00000040;
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
  .left-block {
    text-align: right;
    > div {
      margin-bottom: 1rem;
    }
    h1 {
      font-size: 66px;
      line-height: 1;
      span {
        &:first-child {

        }
        &:last-child {
          color: #ef3123;
        }
      }
    }

    .contacts {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .el-divider {
      width: 83%;
      margin-left: 17%;
      background: #ef3123;
    }
  }
  .right-block {
    display: flex;
    justify-content: center;
    transform: translateY(-0.9rem);
    img {
     width: 76%;
    }
  }

}
