























































































































































































































































































































.buttons {
  margin-top: 20px;
  text-align: right;
}
.new-offer {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 30px;
  .offer-create {

    .pre-result {
      text-align: right;
      ::v-deep .el-input {
        width: 100%;
      }
    }

    ::v-deep .el-form {
      &-item {
        width: 100%;
        margin-bottom: 10px;
        .el-select {
          width: 100%;
          .el-tag {
            background: #ef3123;
            color: #fff;
            &__close {
              background: #fff;
              color: #ef3123;
            }
          }
          &__tags-text {
            line-height: 12px;
          }
        }
        .el-input-number {
          width: 100%;
        }
        &__label {
          font-size: 12px;
          line-height: 1;
          margin-top: 10px;
        }
      }

    }
  }
}
::v-deep .el-table {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}

//.leasing-offer-form {
//  > div {
//    display: grid;
//    grid-template-columns: 250px 1fr;
//    gap: 20px;
//    margin-bottom: 10px;
//    margin-top: 10px;
//    > div {
//      &:first-child {
//        display: flex;
//        align-items: center;
//        justify-content: flex-end;
//      }
//      &:last-child {
//        * {
//          width: 100%;
//        }
//      }
//    }
//  }
//}


::v-deep .el-tabs--border-card > .el-tabs__header {
  background-color: #f5f5f57a;
  border-bottom: 1px solid #f5f5f57a;
  margin: 0;
}

