






































































































































.profile-block {
  h3 {
    font-weight: 500;
    margin-bottom: 20px;
  }

  .address-block {
    margin-bottom: 40px;

    &__item {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 40px;
      @media (max-width: 1230px) {
        gap: 10px;
      }
      @media (max-width: 850px) {
        grid-template-columns: 1fr;
        gap: 0px;

      }
    }
  }

  .btn-block {
    display: flex;
    justify-content: flex-end;

    button {
      @media(max-width: 850px) {
        width: 100% !important;
        margin-bottom: 20px;
      }
    }
  }

}
