






















.checkbox {
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    align-items: flex-start;
    margin-bottom: 5px;
  }

  ::v-deep {
    .el-checkbox__label {
      font-size: 12px;
      color: #555555;
      @media(max-width: 600px) {
        font-size: 10px;
        white-space: normal;
        line-height: 1.6;
      }
    }
  }

  &.is-checked {
    ::v-deep {
      .el-checkbox__label {
        font-size: 12px;
        color: #ef3123;
        @media(max-width: 600px) {
          font-size: 10px;
          white-space: normal;
          line-height: 1.6;
        }
      }
    }
  }
}
