

















































































































































































.group {
  position: relative;
}

.select {
  max-width: 315px;
  width: 100%;
  margin-bottom: 15px;
  @media(max-width: 850px) {
    max-width: unset;
  }

  &.small {
    p {
      font-size: 11px;
    }
  }

  p {
    color: #1A2122;
    font-weight: 500;
    margin-bottom: 5px;

    span {
      font-size: 10px;
      font-weight: normal;
    }

    &.required {
      position: relative;
      margin-left: 10px;

      &:before {
        position: absolute;
        content: '*';
        color: #ef3123;
        font-size: 24px;
        left: -10px;
        top: -2px;
      }
    }
  }

  ::v-deep {
    .el-select {
      width: 100%;

      .el-input__inner {
        font-size: 12px;
      }

      &:hover {
        .el-input__inner {
          border-color: #C0C4CC;
          width: 100%;
          font-size: 12px;
        }

      }

      .el-tag.el-tag--info {
        color: #fff;
        background-color: #ef3123;

        .el-tag__close {
          color: #fff;
          background-color: #ef3123;
        }
      }

      &.warning {
        background: #fff2c5 !important;

        .el-input {
          input {
            background: #fff2c5 !important;
          }
        }
      }
    }
  }

}
