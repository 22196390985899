



















.container{
  max-width: 1360px;
  width: 100%;
  padding: 15px 20px;
  margin: 0 auto;
  @media(max-width: 1024px){
    padding: 10px 15px;
  }
}
.content {
  width: 100%;
  height: 75vh;
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 2px 4px 0px #00000040;
}
