


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.at_work {
  margin-bottom: 1rem;
  text-align: right;

  .my-button {
    margin-bottom: 0.5rem;
  }
}

.old-apps {
  max-height: 200px;
  overflow-y: auto;

  ::v-deep .el-table {
    &__header {
      .cell {
        font-size: 8px;
        color: #121212;
      }
    }

    &__body {
      .cell {
        font-size: 10px;
        color: #121212;

        a {
          font-size: 10px;
        }
      }
    }

    .el-button {
      padding: 2px 6px;
    }

    &__cell {
      padding: 2px 0;
    }
  }
}

.chart {
  .leasing-application-status {
    margin-bottom: 10px;

    span {
      font-weight: bold;

      &:last-child {
        color: #ef3123;
      }
    }
  }

  .disabled {
    opacity: 0.5 !important;
    pointer-events: none;
    transition: .3s;
  }

  .subject-group {
    position: relative;

    i {
      position: absolute;
      top: 25px;
      right: 20%;
      color: #ef3123;
      font-size: 36px;
      cursor: pointer;

      &.x-minus {
        right: 15%;
        @media (max-width: 850px) {
          top: -14px;
          right: 5%;
        }
      }

      @media (max-width: 850px) {
        top: -14px;
        right: -3%;
      }
    }
  }

  .price-block {
    margin-bottom: 15px;
  }

  .prepay-block {
    margin-bottom: 15px;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    @media(max-width: 670px) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  .client-info {
    margin-bottom: 15px;

    &__item {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 40px;
      @media(max-width: 850px) {
        gap: 0;
        grid-template-columns: 1fr;
      }
    }
  }

  .sms {
    &:disabled {
      color: #1A2122;
    }
  }

  .btn-block {
    div {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      @media(max-width: 850px) {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        justify-content: flex-start;
      }
    }
  }
}

.btn-block-2 {
  display: grid;

  button {
    @media(max-width: 850px) {
      width: 100% !important;
      margin-bottom: 20px;
    }
  }

  &__wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
    @media(max-width: 850px) {
      display: block;
      width: 100%;
    }

    div {
      display: grid;
    }

    button {
      min-width: 240px;
      @media(max-width: 850px) {
        width: 100% !important;
        margin-bottom: 20px;
      }

      &.btn-accept {
        @media(max-width: 850px) {
          width: 100%;
          order: -1;
          margin-bottom: 5px;
        }

        i {
          font-size: 20px;
          line-height: 1;
        }
      }
    }

    p {
      padding: 5px 5px;
      text-align: start;
      max-width: 240px;
      font-size: 9px !important;
      color: #555;
      @media(max-width: 850px) {
        max-width: unset;
        width: 100%;

      }
    }
  }

  .next {
    margin-left: auto;
  }

}

::v-deep .el-dialog__wrapper.sms {
  .el-dialog {
    background: rgba(255, 255, 255, 0.8);

    &__body {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;

      .first-stage-sms-content,
      .second-stage-sms-content {
        display: none;
        opacity: 0;
        position: absolute;
        width: 420px;
        height: 130px;
        top: calc(50% - 65px);
        left: calc(50% - 210px);
        transition: all 300ms linear;

        &.active {
          display: block;
          opacity: 1;
        }
      }
    }
  }

}


