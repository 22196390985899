


























































































































































































































































.inputs {
  display: grid;
  grid-template-columns: 1fr 1fr 150px 57px;
  gap: 30px;
  margin-bottom: 20px;
  >div{
    &:first-child{
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  ::v-deep .el-input.is-disabled .el-input__inner {
    background: #ddd;
    color: #1A2122;
    font-weight: bold;
  }

  ::v-deep .el-input {
    border-radius: 8px;
    border: 0 solid #DCDFE6;
    background: #000;

    &-number {
      width: 100%;
    }

    &__inner {

    }
  }

  > div {
    p {
      color: #1A2122;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .add, .remove {
      cursor: pointer;
      display: inline-block;
      background: transparent;
      border: none;
      padding: 0;
      font-size: 24px;

      &:hover {
        opacity: .8;
        scale: 1.1;
      }
    }

    .add {
      display: none;
    }

    .remove {
      color: #000;

      &:disabled {
        color: #ddd;
      }
    }
  }

  .icons {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 30px;
    grid-column-start: 4;

  }

  &:last-child {
    > div {
      .add {
        color: #ef3123;
        display: inline-block;

        &.hide {
          display: none;
        }
      }

      .remove {
        //display: none;
      }
    }

  }

  &:first-child {
    > div {
      .remove {
        display: none;
      }
    }

  }
}
.newInputNumber{
  max-width: 315px;
  margin-bottom: 15px;
  color: #1A2122;
  font-weight: 500;
  height: 40px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  border:solid 1px #DCDFE6;
  &:focus{
    border-color: #ef3123;
    outline: none;
  }
  &:disabled{
    background: #ddd;
  }
}
.newInputNumber::-webkit-outer-spin-button,
.newInputNumber::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
