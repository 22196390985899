


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































h5 {
  margin: 15px 0 5px 0;
  font-weight: 500;
}


::v-deep .el-table {
  &__cell {
    padding: 5px 0;
    font-size: 0.8rem;
  }
  &__header {
    thead {
      color: #1A2122;
      th {
        font-weight: 500;
      }
    }

  }
  &__body {
    font-weight: 300;
  }

}

::v-deep .el-collapse {
  &-item {
    &__header {
      color: #1A2122;
      font-size: 20px;
    }
    &__content {
      color: #1A2122;
    }
    &__wrap {
      background-color: #fcfcfc;
      padding: 10px 20px;
    }
  }

}

.scoring-container {
  > * {
    margin: 15px 0;
  }
}
 .scoring-result {
   display: grid;
   grid-template-columns: 2fr 2fr 1fr;
   .update {
     cursor: pointer;
   }
 }
.card {
  margin-top: 20px;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 20px;
  background: #fff;
  h5 {
    margin-top: 0;

  }
  .sub-card {
    background: #fafafa;
    margin-bottom: 20px;
  }
}
 .fszn-data {
   > div {
     display: grid;
     margin-bottom: 15px;
     grid-template-columns: 1fr 1fr 1fr 1fr;
     &.other-data {
       grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
     }
   }
 }

 .credit-register {
   &__rating {
     display: flex;
     gap: 30px;
     padding: 0 5px;
     //margin-bottom: 30px;
   }
   &__table {
    &-header {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
      &.second {
        grid-template-columns: 2fr 1fr 1fr 1fr;
      }
      &.seven {
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
      }
      > div {
        padding: 5px 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;
        border-right: 1px solid #eee;
        border-top:  1px solid #eee;
      }
      > div {
        &:first-child {
          border-left: 1px solid #eee;
        }
        &:not(:first-child) {
          text-align: center;
        }
      }
    }
    &-body {
      &.second {
        > div {
          grid-template-columns: 2fr 1fr 1fr 1fr;
        }
      }
      &.seven {
        > div {
          grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
        }
      }
      > div {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
        > div {
          border-bottom: 1px solid #eee;
          border-right: 1px solid #eee;
          padding: 5px 10px;

          &:first-child {
            border-left: 1px solid #eee;
          }
          &:not(:first-child) {
            text-align: center;
          }
        }
      }

    }
   }
 }
 .delinquency {
   &.third {
      > div {
        > div {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
   }
   &.four {
      > div {
        > div {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }
   }
   > div {
     > div {
       display: grid;
       grid-template-columns:  1fr 1fr;
       &:last-child {
         border-bottom: 1px solid #eee;
       }
       > div {
         padding: 5px 10px;
         border-right: 1px solid #eee;
         border-top: 1px solid #eee;

         &:first-child {
           border-left: 1px solid #eee;
         }
         &:not(:first-child) {
           text-align: center;
         }
       }
     }
   }
 }

 .table-four-column {
   > div {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr;
     &:last-child {
       border-bottom: 1px solid #eee;
     }
     > div {
       padding: 5px 10px;
       border-right: 1px solid #eee;
       border-top: 1px solid #eee;

       &:first-child {
         border-left: 1px solid #eee;
       }
       &:not(:first-child) {
         text-align: center;
       }
     }
   }
 }

 .table-three-column {
   > div {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr;
     &:last-child {
       border-bottom: 1px solid #eee;
     }
     > div {
       padding: 5px 10px;
       border-right: 1px solid #eee;
       border-top: 1px solid #eee;

       &:first-child {
         border-left: 1px solid #eee;
       }
       &:not(:first-child) {
         text-align: center;
       }
     }
   }
 }
 .after-table {
   padding-left: 5px;
   margin-top: 5px;
   font-size: 0.7rem;
   font-weight: 500;
 }
 .after-table-two-column {
   display: grid;
   grid-template-columns: 1fr 2fr;
   padding-left: 5px;
   margin-top: 5px;
   font-size: 0.7rem;
 }

 .four-columns {
   > div {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr;
     &:last-child {
       border-bottom: 1px solid #eee;
     }
     > div {
       padding: 5px 10px;
       border-right: 1px solid #eee;
       border-top: 1px solid #eee;

       &:first-child {
         border-left: 1px solid #eee;
       }
     }
   }
   margin-bottom: 15px;
 }

 .six-columns {
   > div {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
     &:last-child {
       border-bottom: 1px solid #eee;
     }
     > div {
       padding: 5px 10px;
       border-right: 1px solid #eee;
       border-top: 1px solid #eee;

       &:first-child {
         border-left: 1px solid #eee;
       }
     }

   }
   margin-bottom: 15px;
 }
 .bold-data {
   font-size: .8rem;
   font-weight: 500;
 }

