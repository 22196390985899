

















































































































































































@import "./src/assets/css/project-variables";


.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    img {
      max-width: 140px;
      width: 100%;
    }
  }

  .mobile-wrapper {
    display: none;
    align-items: center;
    gap: 40px;
    position: relative;
    @media(max-width: 1160px) {
      display: flex;
    }

    i {
      font-size: 22px;
    }

    .burger {
      display: none;
      width: 35px;
      height: 35px;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      span {
        display: inline-block;
        position: relative;
        width: 30px;
        height: 2px;
        background: #1A2122;

        &:before, &:after {
          position: absolute;
          content: '';
          width: 30px;
          height: 2px;
          background: #1A2122;
        }

        &:before {
          top: -7px;
        }

        &:after {
          top: 7px;
        }
      }

      @media (max-width: 1160px) {
        display: grid;

      }
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 1160px) {
      display: none;
    }

    .menu {
      flex-basis: 100%;
      margin-right: 50px;

      ul {
        height: 32px;
        overflow: hidden;
        display: flex;
        align-items: center;
        gap: 10px;
        box-sizing: border-box;

        li {
          &.active {
            a {
              color: #ef3123;
            }
          }

          a {
            padding: 10px 5px;
            color: #1A2122;
            font-weight: normal;

          }
        }
      }
    }

    .profile {
      border-left: solid 1px #1A2122;
      padding: 10px 20px 10px 50px;
      display: flex;
      align-items: center;
      gap: 30px;

      i {
        font-size: 20px;
        line-height: 1;
      }

      div {
        cursor: pointer;
      }
    }
  }

}

.el-dropdown-link {
  display: flex;
}

.separator {
  left: 45%;
  height: 100%;
  width: 1px;
  background: #1A2122;
  position: absolute;

}

.main {
  height: calc(100vh - 71px);
  background: #f5f5f57a;
  overflow-y: auto;
}
