




























































































.group {
  position: relative;
  display: inline-block;
}
.group.date {
  max-width: 315px;
  width: 100%;
  margin-bottom: 15px;
  @media(max-width: 850px){
    max-width: unset;
  }
  p {
    color: #1A2122;
    font-weight: 500;
    margin-bottom: 5px;
    span{
      font-size: 10px;
      font-weight: normal;
    }
    &.required {
      position: relative;
      margin-left: 10px;
      &:before {
        position: absolute;
        content: '*';
        color: #ef3123;
        font-size: 24px;
        left: -10px;
        top: -2px;
      }
    }
  }
  ::v-deep{
    .el-date-editor{
      width: 100%;
      .el-input__inner{
        width: 100%;
        font-size: 12px;
      }
      &.warning{
          input {
            background: #fff2c5 !important;
          }

      }
    }

  }

}

