

































.soato-block{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 35px;
}

