




























































































































































































































































































































































































































































































































































































































.command-buttons {
  text-align: right;

  .add, .remove {
    cursor: pointer;
    display: inline-block;
    background: transparent;
    border: none;
    padding: 0;
    font-size: 24px;

    &:hover {
      opacity: .8;
      scale: 1.1;
    }
  }
}

.employees {
  &-header, &-body > div {
    display: grid;
    grid-template-columns: 0.5fr 3.7fr 2.1fr 3fr 3fr 3fr 1.5fr;
    padding: .3rem 1rem;
    gap: 20px;
    font-size: 10px;
  }

  &-header {
    margin-top: 1rem;
  }

  &-body {
    > div {
      cursor: pointer;

      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:nth-child(2n - 1) {
        background: #f5f5f5;
      }
    }
  }
}

::v-deep .el-drawer {
  .el-form {
    padding: 1rem 2rem 1rem 2rem;

    .el-select {
      width: 100%;
    }

  }

  .drawer__title {
    position: absolute;
    top: 2%;
    padding: 0 1rem 0 4.7rem;
    color: #ef3123;

    h4 {
      font-weight: 500;
      font-size: 1.1rem;
    }
  }

}

.controls-api {
  border-top: solid 1px #DCDFE6;
  border-bottom: solid 1px #DCDFE6;
  padding: 10px 0;
  margin-bottom: 20px;

  .api-row {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    &.disabled {
      opacity: 0.4;
      pointer-events: none;

      .generate__key {
        background: gray;
        border: gray;
      }
    }

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      user-select: none;

      p {
        order: 1;
        font-size: 14px;
        color: #1A2122;

      }
    }

    .generate__key {
      padding: 0 13px;
      min-height: 32px;
      font-weight: 500;
      font-size: 13px;
      min-width: 140px;
    }


  }

}

.btns-custom {
  padding: 0 13px;
  min-height: 32px;
  font-weight: 500;
  font-size: 13px;
}


.controls {
  text-align: right;

  > button {
    &:first-child {
      margin-right: 1rem;
    }
  }
}

.change_password {
  padding: 0 13px;
  min-height: 30px;
  font-weight: 500;
  font-size: 13px;
}
.data-table {
  ::v-deep .el-table {
    &__cell {
      padding: 5px 0;
      font-size: 10px;
    }
  }
}
.search {
  display: grid;
  grid-template-columns: 50px 1fr;
  margin: 0.5rem 0;
  .el-input-group__append {
    background-color: transparent;
    color: transparent;
  }
  ::v-deep .el-input {
    margin-top: 5px;
    &-group {
      &__append {
        background-color: transparent !important;
        border: none;
        padding: 0 5px;
      }
    }
  }
}

