






































































































































































































































































































































































.command-buttons {
  text-align: right;

  .add, .remove {
    cursor: pointer;
    display: inline-block;
    background: transparent;
    border: none;
    padding: 0;
    font-size: 24px;

    &:hover {
      opacity: .8;
      scale: 1.1;
    }
  }
}

.trading-posts {
  &-header, &-body > div {
    display: grid;
    grid-template-columns: 0.5fr 11fr 2fr 6fr 2fr 3fr 2fr;
    padding: .3rem 1rem;
    gap: 20px;
    font-size: 10px;
  }

  &-header {
    margin-top: 1rem;
  }

  &-body {
    > div {
      cursor: pointer;
      position: relative;

      i {
        position: absolute;
        right: 1rem;
      }

      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:nth-child(2n - 1) {
        background: #f5f5f5;
      }
    }
  }
}

::v-deep .el-drawer {
  .el-form {
    padding: 1rem 10rem 1rem 2rem;

    .el-select {
      width: 100%;
    }

    .controls {
      text-align: right;

      > button {
        &:first-child {
          margin-right: 1rem;
        }
      }
    }
  }
}
.data-table {
  ::v-deep .el-table {
    &__cell {
      padding: 5px 0;
      font-size: 10px;
    }
  }
}
.search {
  display: grid;
  grid-template-columns: 50px 1fr;
  margin: 0.5rem 0;
  .el-input-group__append {
    background-color: transparent;
    color: transparent;
  }
  ::v-deep .el-input {
    margin-top: 5px;
    &-group {
      &__append {
        background-color: transparent !important;
        border: none;
        padding: 0 5px;
      }
    }
  }
}
