
































































.affiliation{
  max-width: 1103px;
  .wrapper{
    margin-top: 30px;
    .employer{
      display: flex;
      padding: 5px;
      font-size: 10px;
      font-weight: 500;
      justify-content: space-between;
      width: 100%;
      &:nth-child(even){
        background: #d5d5d5;
      }
      div{
        &:first-child{
          span{
            margin-right: 10px;
          }
        }
      }
    }
  }
}
::v-deep {
  .el-radio-button__inner {
    margin: 5px;
    border-radius: 5px;
    font-size: 10px;
    padding: 7px;

  }
  .el-tabs{
    width: 100%;
  }
  .el-tabs__item.is-left{
     font-size: 10px;
    padding-left: 0;
    min-width: 350px;
  }
  .el-tabs__nav-wrap{
    height: 500px !important;
    overflow-y: auto;
  }
  .el-tab-pane{
    position: relative;
  }


}


