















































































.group {
  position: relative;
}
.switch{
  margin-bottom: 15px;
  @media(max-width: 850px){
    max-width: unset;
  }
  p{
    color: #1A2122;
    font-weight: 500;
    margin-bottom: 5px;
    span{
      font-size: 10px;
      font-weight: normal;
    }
    &.required {
      position: relative;
      margin-left: 10px;
      &:before {
        position: absolute;
        content: '*';
        color: #ef3123;
        font-size: 24px;
        left: -10px;
        top: -2px;
      }
    }
  }
  ::v-deep{
    .el-radio-group{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      width: 50%;
      label{
        width: 100%;
        span{
          width: 100%;
          font-size: 12px;
          padding: 13.5px 18px;
          @media(max-width: 450px){
            font-size: 10px;
            padding: 13.5px 10px;
          }
        }
      }
      .el-radio-button__orig-radio:disabled:checked + .el-radio-button__inner {
        background-color: #ef3123;
        color: #fff;
      }
    }
  }
}

